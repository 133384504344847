import CryptoJS from "crypto-js";
import { addSeconds, format, getHours, getMinutes, getSeconds, startOfDay } from "date-fns";
import { store } from "../../app/store";
import { GameState, Languge, LetterState, ShareSocialMedia } from "../enums";
import { ApiSlice } from "../../features/api/Api";
import queryString from "query-string";
import i18n from "../../i18n";
import { GetGameNotificationMessageRes, GuessRow, IComputeGuessResponse } from "../../interfaces";
import { EhhIcon, GeniousIcon, InfluentialIcon, SparkleIcon, VeryGoodIcon, WonderfulIcon } from "../../constants/notificationIcons";
import { IBackground, IResponse, SocialBlindMode, SocialMediaIcons } from "../../interfaces/Types";
import { ErrorStatusCodes } from "../enums/errorStatusCodes";
import { authStore } from "../../features/authenticate/AuthStore";

export const generateRandomNumber = (min: number = 0, max: number = 100): number => Math.floor(Math.random() * (max - min + 1)) + min;

export const computeGuess = async (guess: string, currentLang: Languge): Promise<IComputeGuessResponse> => {
    const res: any = await store.dispatch(
        ApiSlice.endpoints.create.initiate({
            resource: `dailywords/check-word?${queryString.stringify({
                word: guess,
                ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }),
            })}`,
            headers: {
                langCode: currentLang,
                currentDate: createHash(getCurrentDateForHeader),
            },
        })
    );

    if (!res || !res?.data) throw new Error(i18n.t("server_error"));

    if (res.data?.errorMessage) throw new Error(JSON.stringify(res.data));

    return res.data.data;
};

export const deleteCurrentScore = async (): Promise<void> => {
    const res: any = await store.dispatch(
        ApiSlice.endpoints.remove.initiate({
            resource: "dailywords/delete-score",
            headers: {
                currentDate: createHash(getCurrentDateForHeader),
                ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }),
            },
        })
    );

    if (!res || !res?.data) throw new Error(i18n.t("server_error"));

    if (res.data?.errorMessage) throw new Error(JSON.stringify(res.data));
};

export const updateUserScore = async (spentTime: Date, gameStatus: GameState, currentLang: Languge): Promise<void> => {
    const spentTimeSecond: number = getTotalSeconds(spentTime);

    const res: any = await store.dispatch(
        ApiSlice.endpoints.update.initiate({
            resource: "dailywords/update-user-score",
            params: {
                spentTime: spentTimeSecond,
                status: GameState[gameStatus],
                ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }),
            },
            headers: {
                langCode: currentLang,
                currentDate: createHash(getCurrentDateForHeader),
            },
        })
    );

    if (!res || !res?.data) throw new Error(i18n.t("server_error"));

    if (res.data?.errorMessage) throw new Error(JSON.stringify(res.data));
};

export const getTotalSeconds = (date: Date): number => {
    const hours: number = getHours(date);
    const minutes: number = getMinutes(date);
    const seconds: number = getSeconds(date);

    return hours * 3600 + minutes * 60 + seconds;
};

export const secondToDate = (second: number): Date => {
    const startDate: Date = startOfDay(new Date());

    const result: Date = addSeconds(startDate, second);

    return result;
};

export const floatToFixedFloat = (number: number, fixedCount: number = 2) => parseFloat(number.toFixed(fixedCount));

export const getCurrentDateForHeader = `${format(new Date(), "yyyy-MM-dd")}${process.env.REACT_APP_SALT_KEY}`;

export const createHash = (input: string, saltKey?: string) =>
    saltKey
        ? CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(input + saltKey)).toString(CryptoJS.enc.Hex)
        : CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(input)).toString(CryptoJS.enc.Hex);

export const getGameEndNotification = (guessLength: number): GetGameNotificationMessageRes => {
    const guessMessages = [
        "",
        "one_guess_game_ended",
        "two_guess_game_ended",
        "three_guess_game_ended",
        "four_guess_game_ended",
        "five_guess_game_ended",
        "six_guess_game_ended",
    ];

    const guessIcons = ["", GeniousIcon, WonderfulIcon, InfluentialIcon, SparkleIcon, VeryGoodIcon, EhhIcon];

    return { message: i18n.t(guessMessages[guessLength]), icon: guessIcons[guessLength] } as GetGameNotificationMessageRes;
};

export const getErrorNotification = (error: any): GetGameNotificationMessageRes => {
    let errorObject: any;

    try {
        errorObject = JSON.parse(error.message);

        if (isIResponse(errorObject)) {
            switch (errorObject.bundleStatusCode) {
                case ErrorStatusCodes.USER_NOT_FOUND:
                    return { message: i18n.t("user_not_found") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.WORD_NOT_FOUND:
                    return { message: i18n.t("word_not_found"), icon: "❌" } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.INVALID:
                    return { message: i18n.t("invalid_request") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.SYSTEM_ERROR:
                    return { message: i18n.t("server_error") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.CANNOT_PLAY:
                    return { message: i18n.t("user_cannot_play") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.USER_SCORE_NOT_FOUND:
                    return { message: i18n.t("user_score_not_found") } as GetGameNotificationMessageRes;
                default:
                    return { message: errorObject.errorMessage } as GetGameNotificationMessageRes;
            }
        } else if (typeof errorObject === "string") {
            return { message: errorObject } as GetGameNotificationMessageRes;
        }
    } catch {
        return { message: error.message } as GetGameNotificationMessageRes;
    }

    return { message: error } as GetGameNotificationMessageRes;
};

export const isIResponse = (obj: any): obj is IResponse => {
    return typeof obj.bundleStatusCode === "number" && obj.hasOwnProperty("errorMessage") && obj.hasOwnProperty("data");
};

export const isExist = (value: any): boolean => {
    if (value === null || value === undefined || value === "") return false;

    return true;
};

export const getCharacterBackground = (theme: any, state: LetterState, blindMode: boolean): IBackground => {
    const result: IBackground = { className: "", background: "" };

    switch (state) {
        case LetterState.MISS:
            result.className = "miss-char";
            result.background = blindMode ? theme.palette.wordle.blindMissBackground : theme.palette.wordle.missBackground;
            break;
        case LetterState.PRESENT:
            result.className = "present-char";
            result.background = blindMode ? theme.palette.wordle.blindPresentBackground : theme.palette.wordle.presentBackground;
            break;
        case LetterState.MATCH:
            result.className = "match-char";
            result.background = blindMode ? theme.palette.wordle.blindMatchBackground : theme.palette.wordle.matchBackground;
            break;
        default:
            result.className = "";
            result.background = "";
            break;
    }

    return result;
};

export const generateShareContent = (rows: GuessRow[], blindMode: boolean, socialMedia: ShareSocialMedia): string => {
    let resultStr: string = `Wordle Türkçe 1042 ${rows.length}/6\n`;

    rows.forEach((row: GuessRow, rowIndex: number) => {
        row.result?.forEach((letter: LetterState) => {
            const icon: string = getShareCharacterIcon(letter, blindMode, socialMedia);

            resultStr += icon;
        });

        if (rowIndex + 1 !== rows.length) resultStr += "\n";
    });

    return resultStr;
};

const getShareCharacterIcon = (letterState: LetterState, blindMode: boolean, socialMedia: ShareSocialMedia): string => {
    const blindPropertyName: SocialBlindMode = blindMode ? "blind" : "normal";

    const socialIcon: SocialMediaIcons = {
        [ShareSocialMedia.TWITTER]: {
            blind: {
                [LetterState.MISS]: "⬜️",
                [LetterState.PRESENT]: "🟧",
                [LetterState.MATCH]: "🟦",
            },
            normal: {
                [LetterState.MISS]: "⬜️",
                [LetterState.PRESENT]: "🟨",
                [LetterState.MATCH]: "🟩",
            },
        },
        [ShareSocialMedia.FACEBOOK]: {
            blind: {
                [LetterState.MISS]: "⬜️",
                [LetterState.PRESENT]: "🟧",
                [LetterState.MATCH]: "🟦",
            },
            normal: {
                [LetterState.MISS]: "⬜️",
                [LetterState.PRESENT]: "🟨",
                [LetterState.MATCH]: "🟩",
            },
        },
        [ShareSocialMedia.LINKEDIN]: {
            blind: {
                [LetterState.MISS]: "⬜",
                [LetterState.PRESENT]: "⬜",
                [LetterState.MATCH]: "⬜",
            },
            normal: {
                [LetterState.MISS]: "⬜",
                [LetterState.PRESENT]: "⬜",
                [LetterState.MATCH]: "⬜",
            },
        },
        [ShareSocialMedia.WHATSAPP]: {
            blind: {
                [LetterState.MISS]: "⬜️",
                [LetterState.PRESENT]: "🟧",
                [LetterState.MATCH]: "🟦",
            },
            normal: {
                [LetterState.MISS]: "⬜️",
                [LetterState.PRESENT]: "🟨",
                [LetterState.MATCH]: "🟩",
            },
        },
        [ShareSocialMedia.COPYCLIPBOARD]: {
            blind: {
                [LetterState.MISS]: "⬛",
                [LetterState.PRESENT]: "🟦",
                [LetterState.MATCH]: "🟧",
            },
            normal: {
                [LetterState.MISS]: "⬜",
                [LetterState.PRESENT]: "🟨",
                [LetterState.MATCH]: "🟩",
            },
        },
    };

    return socialIcon[socialMedia][blindPropertyName][letterState];
};

export const getFirstChars = (str: string): string => {
    if (!isExist(str)) return "";

    return str
        .split(" ")
        .map((word: string) => word.charAt(0).toUpperCase())
        .join("");
};

export const updateLetterState = (currentState: LetterState | undefined, newState: LetterState): boolean => {
    // Eğer henüz eklenmemişse doğrudan ekle
    if (currentState === undefined) return true;

    // Eğer mevcut durum MATCH ise, herhangi bir düşük öncelikli durum ile değiştirme
    if (currentState === LetterState.MATCH) return false;

    // Eğer mevcut durum PRESENT ise, sadece MATCH ile değiştir
    if (currentState === LetterState.PRESENT) return newState === LetterState.MATCH;

    // MISS durumunda her zaman yeni durumla güncellenebilir
    return true;
};
