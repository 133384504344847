import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocales, useTheme } from "../../../../../../hooks";
import { IPredictionDistribution } from "../../../../../../interfaces";
import { BundleLoadingLineer } from "../../../../../../components";
import { useGetQuery } from "../../../../../../features/api/Api";
import { GUESS_LENGTH } from "../../../../../../constants/settings";
import { authStore } from "../../../../../../features/authenticate/AuthStore";
import { WordleState, WordleStateLocale, wordleStore } from "../../../../../../features/wordle/WordleStore";
import { GameState } from "../../../../../../utils/enums";

const PredictionDistribution = () => {
    const { translate, currentLang } = useLocales();
    const { theme, blindMode }: any = useTheme();
    const wordleState: WordleStateLocale = wordleStore();
    const [predictionUserMaxCount, setPredictionUserMaxCount] = useState<number>(0);
    const [userPredictions, setUserPredictions] = useState<IPredictionDistribution[]>([]);

    const { data: userPredictionsRes, isLoading }: any = useGetQuery({
        resource: "dailywords/user-prediction-distribution",
        params: { ...(authStore.getState().bundleId && { userId: authStore.getState().bundleId }) },
        headers: {
            langCode: currentLang.value,
        },
    });

    useEffect(() => {
        if (userPredictionsRes?.data) {
            let predictions: IPredictionDistribution[] = [...userPredictionsRes.data];

            for (let i = 1; i <= 6; i++) {
                if (!userPredictionsRes.data.some((predictionData: IPredictionDistribution) => predictionData.score === i)) {
                    predictions.push({ count: 0, score: i } as IPredictionDistribution);
                }
            }

            const maxCount: number = Math.max(...predictions.map((prediction: IPredictionDistribution) => prediction.score));

            setPredictionUserMaxCount(maxCount);
            setUserPredictions(predictions);
        }
    }, [userPredictionsRes]);

    return (
        <Box id="prediction-distribution" sx={{ px: { xs: "23px", sm: 0 }, pb: { xs: "12px", sm: 0 } }}>
            <Typography id="prediction-distribution-title">{translate("predictionDistribution")}</Typography>

            <Box id="prediction-distribution-content">
                {isLoading
                    ? [2, 1, 5, 3, 6, 4].map((item: number, index: number) => {
                          let percentage: number = (item / GUESS_LENGTH) * 100;

                          if (percentage === 0) percentage = 10;

                          return (
                              <Box key={index} className="prediction-distribution-content-item">
                                  <Typography className="prediction-distribution-content-item-title">{index + 1}</Typography>

                                  <BundleLoadingLineer width={`${percentage + 10}% !important`} />
                              </Box>
                          );
                      })
                    : userPredictions
                          .sort((a: IPredictionDistribution, b: IPredictionDistribution) => a.score - b.score)
                          .map((prediction: IPredictionDistribution, index: number) => {
                              let percentage: number = (prediction.count / predictionUserMaxCount) * 100;

                              if (percentage === 0) percentage = 1;

                              const userCountBackground: string = getUserBackground(
                                  prediction,
                                  predictionUserMaxCount,
                                  theme,
                                  blindMode,
                                  wordleState[currentLang.value]
                              );

                              return (
                                  <Box key={index} className="prediction-distribution-content-item">
                                      <Typography className="prediction-distribution-content-item-title">{prediction.score}</Typography>

                                      <Box
                                          className="prediction-distribution-content-item-content"
                                          sx={{
                                              width: `${percentage + 1}% !important`,
                                              background: userCountBackground,
                                          }}>
                                          <Typography className="prediction-distribution-content-item-content-title">{prediction.count}</Typography>
                                      </Box>
                                  </Box>
                              );
                          })}
            </Box>
        </Box>
    );
};

const getUserBackground = (
    prediction: IPredictionDistribution,
    predictionUserMaxCount: number,
    theme: any,
    blindMode: boolean,
    wordleState: WordleState
) => {
    const isGameCompleted: boolean = wordleState.gameState === GameState.WON || wordleState.gameState === GameState.LOST;

    if (isGameCompleted && wordleState.rows.length === prediction.score) return "#31AC37";

    const isUserMaxPrediction: boolean = prediction.count === predictionUserMaxCount;

    if (isUserMaxPrediction) return blindMode ? "#599FAC" : "#31AC37";

    return theme.palette.statisticDialog.predictionUserBackground;
};

export default PredictionDistribution;
