import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import WordCharacter from "./WordCharacter";
import { LetterState } from "../../utils/enums";
import { LETTER_LENGTH } from "../../constants/settings";

type IProps = {
    letters: string;
    result?: LetterState[];
    isCurrentRow: boolean;
    maxBoxSize: number;
};

const WordRow: FC<IProps> = (props) => {
    const { letters: lettersProp, result = [], isCurrentRow, maxBoxSize } = props;

    const letterRemaining: number = useMemo(() => LETTER_LENGTH - lettersProp.length, [lettersProp]);
    const letters: string[] = useMemo(() => lettersProp.split("").concat(Array(letterRemaining).fill("")), [lettersProp, letterRemaining]);

    return useMemo(
        () => (
            <Box className="wordle-row">
                {letters.map((char: string, index: number) => (
                    <Box key={index}>
                        <WordCharacter char={char} state={result[index]} isCurrentRow={isCurrentRow} maxBoxSize={maxBoxSize} index={index} />
                    </Box>
                ))}
            </Box>
        ),
        [letters, result, isCurrentRow, maxBoxSize]
    );
};

export default WordRow;
