import { GameSummary, PointTable } from "./common";
import { IValueLabel } from "../../../../../interfaces";
import { GameState, ShareSocialMedia, StatisticTabs } from "../../../../../utils/enums";
import { useLocales, useTheme } from "../../../../../hooks";
import { TransitionProps } from "@mui/material/transitions";
import { CheckIcon, CloseIcon, ShareIcon } from "../../../../../constants/icons";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Tab, Tabs, Typography } from "@mui/material";
import { ReactElement, Ref, SyntheticEvent, forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import StatisticAdwert from "./common/StatisticAdwert";
import { WordleStateLocale, wordleStore } from "../../../../../features/wordle/WordleStore";
import { AuthState, authStore } from "../../../../../features/authenticate/AuthStore";
import useAlert from "../../../../../hooks/useAlert";
import { StatisticContext, StatisticContextType } from "../../../../../states/contexts/StatisticContext";
import { generateShareContent } from "../../../../../utils/methods";

const StatisticDialog = () => {
    const { theme, blindMode }: any = useTheme();
    const { translate, currentLang } = useLocales();
    const wordleState: WordleStateLocale = wordleStore();
    const authState: AuthState = authStore();
    const { showAlert } = useAlert();
    const dialogContentRef: any = useRef<any>(null);
    const adwertRef: any = useRef<any>(null);
    const dialogContentTabsRef: any = useRef<any>(null);
    const pointTableRef: any = useRef<any>(null);
    const [dialogHeightForPointTable, setDialogHeightForPointTable] = useState<number>(0);
    const statisticContext: StatisticContextType = useContext(StatisticContext);

    useEffect(() => {
        const handleResize = () => {
            let totalHeight: number = 0;

            if (dialogContentRef?.current) {
                const tmpRect: DOMRect = dialogContentRef.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (dialogContentTabsRef?.current) {
                const tmpRect: DOMRect = dialogContentTabsRef.current.getBoundingClientRect();

                totalHeight -= tmpRect.height;
            }

            if (adwertRef?.current) {
                const tmpRect: DOMRect = adwertRef.current.getBoundingClientRect();

                totalHeight -= tmpRect.height;
            }

            setDialogHeightForPointTable(totalHeight - 50);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, [dialogContentRef?.current, adwertRef?.current, dialogContentTabsRef?.current, pointTableRef?.current, statisticContext.selectedTab]);

    const closeHandler = useCallback(() => {
        statisticContext.setIsOpenStatisticDialog(false);
    }, [statisticContext.setIsOpenStatisticDialog]);

    const tabChangeHandler = useCallback(
        (_event: SyntheticEvent, newValue: number) => {
            statisticContext.setSelectedTab(newValue);
        },
        [statisticContext]
    );

    const shareHandler = useCallback(async () => {
        if (wordleState[currentLang.value].gameState === GameState.PLAYING) {
            statisticContext.setIsOpenStatisticDialog(false);
            return showAlert(translate("share_not_game_ended"), 99999);
        }

        await navigator.clipboard.writeText(generateShareContent(wordleState[currentLang.value].rows, blindMode, ShareSocialMedia.COPYCLIPBOARD));

        showAlert(translate("copy_share_score"), 2000, <CheckIcon />, undefined, {
            "& .MuiDialog-container": {
                top: "0 !important",
            },
            "& .MuiBackdrop-root": {
                backgroundColor: "transparent",
            },
            "& .MuiDialog-paper": {
                border: "none !important",
                borderRadius: "12px !important",
                background: `#FFF !important`,
                //background: `${theme.palette.statisticDialog.copyShareScoreBackground} !important`,
                boxShadow: "0px 14px 24px 0px rgba(0, 0, 0, 0.15) !important",

                "& .content": {
                    gap: "15px !important",
                },

                "& .content-text": {
                    color: "#31AC37 !important",
                    textOverflow: "ellipsis",
                    fontWeight: "600 !important",
                },
            },
        });
    }, [wordleState, currentLang]);

    useEffect(() => {
        let timeOut: NodeJS.Timeout;

        if (wordleState[currentLang.value].gameState !== GameState.PLAYING) {
            timeOut = setTimeout(() => {
                statisticContext.setIsOpenStatisticDialog(true);
            }, 3000);
        }

        return () => {
            timeOut && clearTimeout(timeOut);
        };
    }, [wordleState[currentLang.value].gameState]);

    return (
        <Box id="statistic-dialog-box">
            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        background: theme.palette.statisticDialog.background,
                    },
                    "& .MuiPaper-root": {
                        ...(statisticContext.selectedTab === StatisticTabs.PointTable && authState.isAuthenticate && { height: "85vh" }),
                    },
                }}
                open={statisticContext.isOpenStatisticDialog}
                onClose={closeHandler}
                TransitionComponent={Transition}
                maxWidth="sm"
                fullWidth
                id="statistic-dialog">
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="statistic-dialog-title">{translate("statistic")}</Typography>
                        <Box
                            sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}
                            id="share-button"
                            aria-haspopup="true"
                            onClick={shareHandler}>
                            <Typography className="statistic-dialog-share">{translate("share")}</Typography>
                            <Box sx={{ display: "flex" }}>
                                <ShareIcon color={theme.palette.statisticDialog.icon} />
                            </Box>
                        </Box>
                    </Box>

                    <Box className="statistic-dialog-close-box" sx={{ background: theme.palette.statisticDialog.background }}>
                        <IconButton aria-label="close" onClick={closeHandler}>
                            <CloseIcon color={theme.palette.statisticDialog.icon} />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent id="statistic-dialog-content" ref={dialogContentRef}>
                    <Tabs
                        sx={{ background: theme.palette.statisticDialog.tabTitleBackground }}
                        value={statisticContext.selectedTab}
                        onChange={tabChangeHandler}
                        variant="scrollable"
                        scrollButtons={false}
                        ref={dialogContentTabsRef}
                        id="statistic-tab">
                        {statisticTabs.map((tab: IValueLabel<StatisticTabs>, index: number) => (
                            <Tab
                                className="statistic-tab-item"
                                sx={{ "&.Mui-selected": { background: blindMode ? "#599FAC" : "#31ac37" } }}
                                key={index}
                                label={translate(tab.label)}
                            />
                        ))}
                    </Tabs>

                    <Box>
                        {statisticContext.selectedTab === StatisticTabs.GameSummary && <GameSummary />}
                        {statisticContext.selectedTab === StatisticTabs.PointTable && (
                            <PointTable pointRef={pointTableRef} spaceHeight={dialogHeightForPointTable} />
                        )}
                    </Box>

                    {!authState.isPremium && (
                        <Box ref={adwertRef}>
                            <StatisticAdwert />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const statisticTabs: IValueLabel<StatisticTabs>[] = [
    {
        label: "gameSummary",
        value: StatisticTabs.GameSummary,
    },
    {
        label: "pointTable",
        value: StatisticTabs.GameSummary,
    },
];

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default StatisticDialog;
