import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocales, useTheme } from "../../hooks";
import { Box, Typography } from "@mui/material";
import { GameState, LetterState } from "../../utils/enums";
import { WordleStateLocale, wordleStore } from "../../features/wordle/WordleStore";
import { IBackground } from "../../interfaces/Types";
import { getCharacterBackground, isExist } from "../../utils/methods";

type IProps = {
    char: string;
    state: LetterState;
    isCurrentRow: boolean;
    maxBoxSize: number;
    index: number;
};

const WordCharacter: FC<IProps> = (props) => {
    const { char, state, isCurrentRow, maxBoxSize, index } = props;
    const { theme, blindMode }: any = useTheme();
    const charRef = useRef<any>(null);
    const [isFlipAnimation, setIsFlipAnimation] = useState<boolean>(false);
    const [background, setIsBackground] = useState<IBackground>(getCharacterBackground(theme, state, blindMode));
    const [stateBackground, setStateBackground] = useState<string>();
    const [animationDelay] = useState<number>(0.2 * index);
    const [color, setColor] = useState<string>();
    const wordleState: WordleStateLocale = wordleStore();
    const { currentLang } = useLocales();

    useEffect(() => {
        if (isExist(char) && isCurrentRow) {
            setIsFlipAnimation(true);

            setTimeout(() => {
                setIsFlipAnimation(false);
            }, 1000);
        } else setIsFlipAnimation(false);
    }, [char, isCurrentRow]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setIsBackground(getCharacterBackground(theme, state, blindMode));
        }, 100);

        return () => {
            clearTimeout(timeOut);
        };
    }, [theme, state, blindMode]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setStateBackground(background.background);
        }, animationDelay * 1000 + 250);

        return () => {
            clearTimeout(timeOut);
        };
    }, [background, animationDelay]);

    useEffect(() => {
        setTimeout(() => {
            setColor(
                wordleState[currentLang.value].gameState === GameState.WON && isCurrentRow
                    ? "#ffffff"
                    : isCurrentRow
                    ? theme.palette.wordle.currentRowTextColor
                    : "#ffffff"
            );
        }, animationDelay * 1000 + 250);
    }, [theme, isCurrentRow, wordleState, animationDelay, currentLang]);

    const animationClassName = useMemo(() => {
        const isWon = wordleState[currentLang.value].gameState === GameState.WON;
        return isWon && isCurrentRow ? "win-char" : background?.className ?? "";
    }, [wordleState, currentLang.value, isCurrentRow, background?.className]);

    return useMemo(
        () => (
            <Box
                className={`wordle-row-character-box ${animationClassName}`}
                component="span"
                ref={charRef}
                sx={{ ml: index !== 0 ? "10px" : "", animationDelay: `${animationDelay}s` }}>
                <Typography
                    className={`wordle-row-character ${isFlipAnimation ? "flipOut" : ""}`}
                    sx={{
                        background: stateBackground,
                        borderColor: `${theme.palette.wordle.characterBorder} !important`,
                        fontSize: { xs: 20, sm: 34 },
                        height: `${maxBoxSize - parseFloat(theme.spacing(2))}px !important`,
                        width: `${maxBoxSize - parseFloat(theme.spacing(2))}px !important`,
                        color,
                    }}>
                    {char}
                </Typography>
            </Box>
        ),
        [animationClassName, index, animationDelay, stateBackground, maxBoxSize, char, isFlipAnimation, color, theme]
    );
};

export default WordCharacter;
