import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { useLocales } from "../../../../../../hooks";
import { ArrowIcon2 } from "../../../../../../constants/icons";
import { AuthState, authStore } from "../../../../../../features/authenticate/AuthStore";

const BundlePremiumChangeBlock = () => {
    const { translate } = useLocales();
    const authState: AuthState = authStore();

    return (
        <Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} onClick={() => authState.changePremium()}>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>{translate("bundlePremiumChange")}</Typography>

                <Box sx={{ mr: 2 }}>
                    <ArrowIcon2 width={20} height={12} />
                </Box>
            </Box>
        </Fragment>
    );
};

export default BundlePremiumChangeBlock;
