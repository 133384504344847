import { useEffect, useState } from "react";
import { AuthState, authStore } from "../../../features/authenticate/AuthStore";
import { useCreateMutation, useUpdateMutation } from "../../../features/api/Api";
import { isExist } from "../../../utils/methods";
import { useLocales } from "../../../hooks";

declare global {
    interface Window {
        bundleMobile: any;
    }
}

const WordleScreenInitialHandler = () => {
    const { translate }: any = useLocales();
    const authState: AuthState = authStore();
    const [create] = useCreateMutation();
    const [update] = useUpdateMutation();
    const [isRunnedCode, setIsRunnedCode] = useState<boolean>(false);

    useEffect(() => {
        if (window.bundleMobile && !isRunnedCode) {
            const handleUserRegistration = async () => {
                let isVisitedStr: string | null = localStorage.getItem("isVisited");

                if (!isVisitedStr) {
                    isVisitedStr = "false";
                    localStorage.setItem("isVisited", "true");
                }

                const isVisited: boolean = isVisitedStr === "true";

                if (!isVisited) {
                    await create({
                        resource: "dailywords/register-user",
                        params: {
                            userId: isExist(window.bundleMobile.bundleId) ? window.bundleMobile.bundleId : window.bundleMobile.anonymousUserId,
                            deviceToken: window.bundleMobile.deviceId,
                        },
                    });
                }

                if (window.bundleMobile.accessToken && window.bundleMobile.bundleId) {
                    authState.useLogin(window.bundleMobile.accessToken, window.bundleMobile.bundleId, window.bundleMobile.deviceId);

                    await update({
                        resource: "dailywords/check-devicetoken",
                        headers: {
                            userId: window.bundleMobile.bundleId,
                            deviceToken: window.bundleMobile.deviceId,
                        },
                    });
                } else authState.useAnonymousLogin(window.bundleMobile.anonymousUserId, window.bundleMobile.deviceId);

                await create({
                    resource: "dailywords/register-user",
                    params: {
                        userId: isExist(window.bundleMobile.bundleId) ? window.bundleMobile.bundleId : window.bundleMobile.anonymousUserId,
                        deviceToken: window.bundleMobile.deviceId,
                    },
                })
                    .unwrap()
                    .then((res: any) => {
                        const userName: string = window.bundleMobile.accessToken ? res.data.userName : translate("anonymous");

                        authState.setUserInfo(userName, res.data.image, res.data.isPremium);
                    });

                setIsRunnedCode(true);
            };

            handleUserRegistration();
        }
    }, [window.bundleMobile, isRunnedCode]);

    return null;
};

export default WordleScreenInitialHandler;
