import { useEffect, useState } from "react";
import { Layout } from "./components";
import useTheme from "./hooks/useTheme";
import { Box, ThemeProvider } from "@mui/material";
import { SplashScreen, WordleScreen } from "./screens";
import { SettingProvider } from "./states";
import { WordleStateLocale, wordleStore } from "./features/wordle/WordleStore";
import { useLocales } from "./hooks";
import { differenceInDays } from "date-fns";
import NotificationAlertProvider from "./states/providers/NotificationAlertProvider";
import StatisticProvider from "./states/providers/StatisticProvider";

const App = () => {
    const { theme }: any = useTheme();
    const [progress, setProgress] = useState<number>(0);
    const [isCompleteSplashScreen, setIsCompleteSplashScreen] = useState<boolean>(false);
    const wordleState: WordleStateLocale = wordleStore();
    const { currentLang } = useLocales();

    useEffect(() => {
        const isNotSameDay: boolean = differenceInDays(wordleState[currentLang.value].gameTime, new Date()) !== 0;

        if (isNotSameDay) wordleState.clear();
    }, [currentLang]);

    return (
        <ThemeProvider theme={theme}>
            <NotificationAlertProvider>
                <SettingProvider>
                    <StatisticProvider>
                        <Box sx={{ visibility: isCompleteSplashScreen ? "visible" : "hidden" }}>
                            <Layout isCompleteSplashScreen={isCompleteSplashScreen}>
                                <WordleScreen isCompleteSplashScreen={isCompleteSplashScreen} />
                            </Layout>
                        </Box>
                        {!isCompleteSplashScreen && (
                            <SplashScreen
                                progress={progress}
                                setProgress={setProgress}
                                isCompleteSplashScreen={isCompleteSplashScreen}
                                setIsCompleteSplashScreen={setIsCompleteSplashScreen}
                            />
                        )}
                    </StatisticProvider>
                </SettingProvider>
            </NotificationAlertProvider>
        </ThemeProvider>
    );
};

export default App;
