import { Fragment, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import useLocales from "../../../../../../hooks/useLocales";
import { BundleSlider } from "../../../../../../components";
import useTheme from "../../../../../../hooks/useTheme";

const SettingHowToPlayBlock = () => {
    const { translate } = useLocales();
    const { mode, blindMode } = useTheme();
    const imageNamePart: string = useMemo(() => (blindMode ? `blind-${mode}` : mode), [mode, blindMode]);

    return (
        <Fragment>
            <Box sx={{ display: "flex", gap: "17px", flexDirection: "column" }}>
                <Box>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, ml: "25px" }}>{translate("howToPlay")}</Typography>
                </Box>
                <Box>
                    <BundleSlider>
                        <Box>
                            <img src={`/assets/images/how-to-play-${imageNamePart}-1.png`} alt="" width="100%" />
                        </Box>
                        <Box>
                            <img src={`/assets/images/how-to-play-${imageNamePart}-2.png`} alt="" width="100%" />
                        </Box>
                        <Box>
                            <img src={`/assets/images/how-to-play-${imageNamePart}-3.png`} alt="" width="100%" />
                        </Box>
                        <Box>
                            <img src={`/assets/images/how-to-play-${imageNamePart}-4.png`} alt="" width="100%" />
                        </Box>
                        <Box>
                            <img src={`/assets/images/how-to-play-${imageNamePart}-5.png`} alt="" width="100%" />
                        </Box>
                        <Box>
                            <img src={`/assets/images/how-to-play-${imageNamePart}-6.png`} alt="" width="100%" />
                        </Box>
                    </BundleSlider>
                </Box>
            </Box>
        </Fragment>
    );
};

export default SettingHowToPlayBlock;
