import { Languge } from "../utils/enums";
import { IValueLabel } from "../interfaces";
import { useTranslation } from "react-i18next";

export default function useLocales(...nameSpaces: string[]) {
    const { i18n, t: translate } = useTranslation(nameSpaces);

    const languages: IValueLabel<Languge>[] = [
        {
            label: translate("tr"),
            value: Languge.tr,
        },
        {
            label: translate("en"),
            value: Languge.en,
        },
        {
            label: translate("de"),
            value: Languge.de,
        },
    ];

    const language: string = i18n.language;

    const currentLang: IValueLabel<Languge> = languages.find((_language: IValueLabel<Languge>) => _language.value === language) || languages[0];

    const languageChangeHandler = (newlang: string): void => {
        i18n.changeLanguage(newlang);

        localStorage.setItem("i18nextLng", newlang);
    };

    return {
        onChangeLang: languageChangeHandler,
        translate,
        currentLang,
        allLang: languages,
    };
}
