import { FC } from "react";
import useThemeMode from "../hooks/useTheme";
import { ThemeMode } from "../utils/enums";

type IProps = {
    width?: number;
    height?: number;
    color?: string;
};

export const HomeIcon: FC<IProps> = ({ color = "black", width = 20, height = 20 }) => {
    const { mode } = useThemeMode();

    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.33333 18V11.9136C7.33333 11.3534 7.8109 10.8992 8.4 10.8992H11.6C12.1891 10.8992 12.6667 11.3534 12.6667 11.9136V18M9.38184 2.18771L2.44851 6.87647C2.16713 7.06676 2 7.3748 2 7.70316V16.4784C2 17.3188 2.71634 18 3.6 18H16.4C17.2837 18 18 17.3188 18 16.4784V7.70316C18 7.3748 17.8329 7.06676 17.5515 6.87647L10.6182 2.18771C10.2481 1.93743 9.75193 1.93743 9.38184 2.18771Z"
                stroke={mode === ThemeMode.light ? color : "#FFFFFF"}
                strokeLinecap="round"
            />
        </svg>
    );
};

export const ProfileIcon: FC<IProps> = ({ color = "black", width = 20, height = 21 }) => {
    const { mode } = useThemeMode();

    return (
        <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.63822 17.3501C2.92519 17.0287 5.71487 15.1647 6.67313 14.4183C7.02213 14.1464 7.45023 14.0002 7.89262 14.0002C8.96233 14.0002 11.0237 14.0002 12.0985 14.0002C12.5461 14.0002 12.9785 14.1526 13.3388 14.4181C14.5761 15.3296 15.7361 16.0088 17 17.0002M5 18.5H15C16.6569 18.5 18 17.1569 18 15.5V5.5C18 3.84315 16.6569 2.5 15 2.5H5C3.34315 2.5 2 3.84315 2 5.5V15.5C2 17.1569 3.34315 18.5 5 18.5ZM12.8655 8.2267C12.8655 6.70044 11.577 5.4534 10 5.4534C8.42302 5.4534 7.13454 6.70044 7.13454 8.2267C7.13454 9.75296 8.42302 11 10 11C11.577 11 12.8655 9.75296 12.8655 8.2267Z"
                stroke={mode === ThemeMode.light ? color : "#FFFFFF"}
            />
        </svg>
    );
};

export const StatisticsIcon: FC<IProps> = ({ color = "black", width = 20, height = 20 }) => {
    const { mode } = useThemeMode();

    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.54706 17.5V3.48658C7.54706 2.94171 7.98877 2.5 8.53364 2.5H11.609C12.1539 2.5 12.5956 2.94171 12.5956 3.48658V17.5M7.54706 17.5L7.54827 13.0318C7.54842 12.4868 7.10667 12.045 6.56169 12.045H3.48658C2.94171 12.045 2.5 12.4867 2.5 13.0315V16.5134C2.5 17.0583 2.94171 17.5 3.48658 17.5H7.54706ZM7.54706 17.5H12.5956M12.5956 17.5V8.00542C12.5956 7.46054 13.0373 7.01883 13.5822 7.01883H16.5134C17.0583 7.01883 17.5 7.46054 17.5 8.00542V16.5134C17.5 17.0583 17.0583 17.5 16.5134 17.5H12.5956Z"
                stroke={mode === ThemeMode.light ? color : "#FFFFFF"}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const ArrowIcon: FC<IProps> = ({ color = "black", width = 10, height = 8 }) => {
    const { mode } = useThemeMode();

    return (
        <svg width={width} height={height} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.35355 4.35355C9.54882 4.15829 9.54882 3.84171 9.35355 3.64645L6.17157 0.464466C5.97631 0.269203 5.65973 0.269203 5.46447 0.464466C5.2692 0.659728 5.2692 0.97631 5.46447 1.17157L8.29289 4L5.46447 6.82843C5.2692 7.02369 5.2692 7.34027 5.46447 7.53553C5.65973 7.7308 5.97631 7.7308 6.17157 7.53553L9.35355 4.35355ZM4.37114e-08 4.5L9 4.5L9 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                fill={mode === ThemeMode.light ? color : "#FFFFFF"}
            />
        </svg>
    );
};

export const ArrowIcon2: FC<IProps> = ({ color = "black", width = 11, height = 12 }) => {
    const { mode } = useThemeMode();

    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Arrow 2"
                d="M1 5.27239C0.598154 5.27239 0.272393 5.59815 0.272393 6C0.272393 6.40185 0.598154 6.72761 1 6.72761L1 5.27239ZM10.5145 6.51449C10.7986 6.23035 10.7986 5.76965 10.5145 5.4855L5.88403 0.855042C5.59988 0.570894 5.13919 0.570894 4.85504 0.855042C4.57089 1.13919 4.57089 1.59989 4.85504 1.88403L8.97101 6L4.85504 10.116C4.57089 10.4001 4.57089 10.8608 4.85504 11.145C5.13919 11.4291 5.59989 11.4291 5.88403 11.145L10.5145 6.51449ZM1 6.72761L10 6.72761L10 5.27239L1 5.27239L1 6.72761Z"
                fill={mode === ThemeMode.light ? color : "#FFFFFF"}
            />
        </svg>
    );
};

export const ArrowWithoutLineIcon: FC<IProps> = ({ color = "#0A0E12", width = 24, height = 24 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 12.0054C17 11.7892 16.9255 11.6054 16.766 11.4541L8.26596 3.21622C8.11702 3.07568 7.94681 3 7.73404 3C7.32979 3 7 3.32432 7 3.74595C7 3.95135 7.08511 4.13514 7.21277 4.27568L15.2021 12.0054L7.21277 19.7351C7.08511 19.8649 7 20.0486 7 20.2541C7 20.6865 7.32979 21 7.73404 21C7.94681 21 8.11702 20.9243 8.26596 20.7838L16.766 12.5568C16.9255 12.4054 17 12.2216 17 12.0054Z"
                fill={color}
            />
        </svg>
    );
};

export const PrevIcon: FC<IProps> = ({ color = "#B3BAC1", width = 34, height = 34 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.422 10.5095C15.6872 10.1913 16.1601 10.1483 16.4783 10.4134C16.7965 10.6786 16.8395 11.1515 16.5744 11.4698L15.422 10.5095ZM10.9895 17.0001L10.4133 17.4802C10.1815 17.2021 10.1815 16.7981 10.4133 16.5199L10.9895 17.0001ZM16.5744 22.5304C16.8395 22.8486 16.7965 23.3215 16.4783 23.5867C16.1601 23.8519 15.6872 23.8089 15.422 23.4907L16.5744 22.5304ZM23.0104 16.2501C23.4246 16.2501 23.7604 16.5859 23.7604 17.0001C23.7604 17.4143 23.4246 17.7501 23.0104 17.7501V16.2501ZM16.5744 11.4698L11.5656 17.4802L10.4133 16.5199L15.422 10.5095L16.5744 11.4698ZM11.5656 16.5199L16.5744 22.5304L15.422 23.4907L10.4133 17.4802L11.5656 16.5199ZM10.9895 16.2501L23.0104 16.2501V17.7501L10.9895 17.7501V16.2501ZM7.91366 7.9137C2.89542 12.9319 2.89542 21.0681 7.91366 26.0863L6.85299 27.147C1.24897 21.543 1.24897 12.4571 6.85299 6.85304L7.91366 7.9137ZM7.91366 26.0863C12.9319 31.1046 21.0681 31.1046 26.0863 26.0863L27.147 27.147C21.5429 32.751 12.457 32.751 6.85299 27.147L7.91366 26.0863ZM26.0863 26.0863C31.1045 21.0681 31.1045 12.9319 26.0863 7.9137L27.147 6.85304C32.751 12.4571 32.751 21.543 27.147 27.147L26.0863 26.0863ZM26.0863 7.9137C21.0681 2.89547 12.9319 2.89547 7.91366 7.9137L6.85299 6.85304C12.457 1.24902 21.5429 1.24902 27.147 6.85304L26.0863 7.9137Z"
                fill={color}
            />
        </svg>
    );
};

export const NextIcon: FC<IProps> = ({ color = "#B3BAC1", width = 34, height = 34 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.578 10.5095C18.3128 10.1913 17.8399 10.1483 17.5217 10.4134C17.2035 10.6786 17.1605 11.1515 17.4256 11.4698L18.578 10.5095ZM23.0105 17.0001L23.5867 17.4802C23.8185 17.2021 23.8185 16.7981 23.5867 16.5199L23.0105 17.0001ZM17.4256 22.5304C17.1605 22.8486 17.2035 23.3215 17.5217 23.5867C17.8399 23.8519 18.3128 23.8089 18.578 23.4907L17.4256 22.5304ZM10.9896 16.2501C10.5754 16.2501 10.2396 16.5859 10.2396 17.0001C10.2396 17.4143 10.5754 17.7501 10.9896 17.7501V16.2501ZM17.4256 11.4698L22.4344 17.4802L23.5867 16.5199L18.578 10.5095L17.4256 11.4698ZM22.4344 16.5199L17.4256 22.5304L18.578 23.4907L23.5867 17.4802L22.4344 16.5199ZM23.0105 16.2501L10.9896 16.2501V17.7501L23.0105 17.7501V16.2501ZM26.0863 7.9137C31.1046 12.9319 31.1046 21.0681 26.0863 26.0863L27.147 27.147C32.751 21.543 32.751 12.4571 27.147 6.85304L26.0863 7.9137ZM26.0863 26.0863C21.0681 31.1046 12.9319 31.1046 7.9137 26.0863L6.85304 27.147C12.4571 32.751 21.543 32.751 27.147 27.147L26.0863 26.0863ZM7.9137 26.0863C2.89547 21.0681 2.89547 12.9319 7.9137 7.9137L6.85304 6.85304C1.24902 12.4571 1.24902 21.543 6.85304 27.147L7.9137 26.0863ZM7.9137 7.9137C12.9319 2.89547 21.0681 2.89547 26.0863 7.9137L27.147 6.85304C21.543 1.24902 12.4571 1.24902 6.85304 6.85304L7.9137 7.9137Z"
                fill={color}
            />
        </svg>
    );
};

export const BackspaceIcon: FC<IProps> = ({ color = "black", width = 23, height = 22 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 9.03875L12.8245 11.3632M12.8245 11.3632L15.1489 13.6877M12.8245 11.3632L15.1489 9.03875M12.8245 11.3632L10.5 13.6877M7.32418 17.1743L19.2167 17.1743C19.8586 17.1743 20.379 16.654 20.379 16.0121V6.71429C20.379 6.07241 19.8586 5.55206 19.2167 5.55206L7.32418 5.55206L3.35727 10.8168C3.11352 11.1403 3.11352 11.5861 3.35727 11.9096L7.32418 17.1743Z"
                stroke={color}
                strokeWidth="1.36199"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const CloseIcon: FC<IProps> = ({ color = "black", width = 20, height = 20 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.2882 14.9617C16.4644 15.1379 16.5633 15.3767 16.5633 15.6258C16.5633 15.8749 16.4644 16.1137 16.2882 16.2899C16.1121 16.466 15.8733 16.5649 15.6242 16.5649C15.3751 16.5649 15.1362 16.466 14.9601 16.2899L9.99997 11.3281L5.03825 16.2883C4.86213 16.4644 4.62326 16.5634 4.37418 16.5634C4.12511 16.5634 3.88624 16.4644 3.71012 16.2883C3.534 16.1122 3.43506 15.8733 3.43506 15.6242C3.43506 15.3752 3.534 15.1363 3.71012 14.9602L8.67184 10L3.71168 5.0383C3.53556 4.86218 3.43662 4.62331 3.43662 4.37423C3.43662 4.12516 3.53556 3.88629 3.71168 3.71017C3.8878 3.53405 4.12668 3.43511 4.37575 3.43511C4.62482 3.43511 4.86369 3.53405 5.03981 3.71017L9.99997 8.67189L14.9617 3.70939C15.1378 3.53327 15.3767 3.43433 15.6257 3.43433C15.8748 3.43433 16.1137 3.53327 16.2898 3.70939C16.4659 3.88551 16.5649 4.12438 16.5649 4.37345C16.5649 4.62252 16.4659 4.86139 16.2898 5.03751L11.3281 10L16.2882 14.9617Z"
                fill={color}
            />
        </svg>
    );
};

export const LoginIcon: FC<IProps> = ({ color = "black", width = 15, height = 18 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.75012 6.31602C1.75012 6.52278 1.58207 6.69084 1.37531 6.69084C1.16805 6.69084 1.00049 6.52278 1.00049 6.31602V4.75649C1.00049 3.72317 1.42283 2.78393 2.10387 2.10338C2.78442 1.42283 3.72366 1 4.75698 1H10.2435C11.2768 1 12.2161 1.42283 12.8966 2.10338C13.5772 2.78393 14 3.72317 14 4.75649V13.2425C14 14.2758 13.5777 15.2151 12.8966 15.8956C12.2161 16.5762 11.2768 16.999 10.2435 16.999H4.75698C3.72366 16.999 2.78442 16.5762 2.10387 15.8956C1.42332 15.2151 1.00049 14.2758 1.00049 13.2425V11.683C1.00049 11.4762 1.16854 11.3082 1.37531 11.3082C1.58207 11.3082 1.75012 11.4762 1.75012 11.683V13.2425C1.75012 14.0691 2.08868 14.8202 2.63351 15.3655C3.17883 15.9103 3.92994 16.2489 4.75649 16.2489H10.243C11.0696 16.2489 11.8207 15.9103 12.366 15.3655C12.9108 14.8202 13.2494 14.0691 13.2494 13.2425V4.75649C13.2494 3.92994 12.9108 3.17883 12.366 2.63351C11.8207 2.08868 11.0696 1.75012 10.243 1.75012H4.75649C3.92994 1.75012 3.17883 2.08868 2.63351 2.63351C2.08868 3.17883 1.75012 3.92994 1.75012 4.75649V6.31602ZM5.06173 6.85644C4.92945 6.6977 4.95002 6.46154 5.10877 6.32925C5.26752 6.19647 5.50367 6.21754 5.63596 6.37629L7.62469 8.75992C7.74375 8.90201 7.73885 9.10681 7.62224 9.24351L5.63596 11.6237C5.50318 11.7825 5.26703 11.8035 5.10877 11.6707C4.95051 11.5385 4.92945 11.3023 5.06173 11.1436L6.53748 9.37531H1.37482C1.16757 9.37531 1 9.20725 1 9.00049C1 8.79324 1.16805 8.62567 1.37482 8.62567H6.53748L5.06173 6.85742V6.85644Z"
                fill={color}
                stroke={color}
                strokeWidth="0.577785"
            />
        </svg>
    );
};

export const LogoutIcon: FC<IProps> = ({ color = "black", width = 15, height = 18 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.74963 6.31602C1.74963 6.52278 1.58158 6.69084 1.37482 6.69084C1.16757 6.69084 1 6.52278 1 6.31602V4.75649C1 3.72317 1.42234 2.78393 2.10338 2.10338C2.78393 1.42283 3.72318 1 4.75649 1H10.243C11.2763 1 12.2156 1.42283 12.8961 2.10338C13.5767 2.78393 13.9995 3.72317 13.9995 4.75649V13.2425C13.9995 14.2758 13.5772 15.2151 12.8961 15.8956C12.2156 16.5762 11.2763 16.999 10.243 16.999H4.75649C3.72318 16.999 2.78393 16.5762 2.10338 15.8956C1.42283 15.2151 1 14.2758 1 13.2425V11.683C1 11.4762 1.16805 11.3082 1.37482 11.3082C1.58158 11.3082 1.74963 11.4762 1.74963 11.683V13.2425C1.74963 14.0691 2.08819 14.8202 2.63302 15.3655C3.17834 15.9103 3.92945 16.2489 4.756 16.2489H10.2425C11.0691 16.2489 11.8202 15.9103 12.3655 15.3655C12.9103 14.8202 13.2489 14.0691 13.2489 13.2425V4.75649C13.2489 3.92994 12.9103 3.17883 12.3655 2.63351C11.8202 2.08868 11.0691 1.75012 10.2425 1.75012H4.756C3.92945 1.75012 3.17834 2.08868 2.63302 2.63351C2.08819 3.17883 1.74963 3.92994 1.74963 4.75649V6.31602Z"
                fill={color}
                stroke={color}
                strokeWidth="0.577785"
            />
            <path
                d="M4.60217 6.32924C4.76091 6.46152 4.78149 6.69768 4.6492 6.85643L3.17346 8.62566H8.33612C8.54288 8.62566 8.71094 8.79322 8.71094 9.00047C8.71094 9.20724 8.54337 9.37529 8.33612 9.37529H3.17346L4.6492 11.1435C4.78149 11.3023 4.76042 11.5384 4.60217 11.6707C4.44391 11.8035 4.20775 11.7824 4.07498 11.6237L2.08869 9.24349C1.97208 9.1068 1.96718 8.90199 2.08624 8.75991L4.07498 6.37627C4.20726 6.21753 4.44342 6.19646 4.60217 6.32924Z"
                fill={color}
                stroke={color}
                strokeWidth="0.577785"
            />
        </svg>
    );
};

export const ShareIcon: FC<IProps> = ({ color = "black", width = 16, height = 16 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.0001 7.63934L7.60006 3.39999L7.60006 5.79999C1.99994 6.99999 1.99994 12.6 1.99994 12.6C1.99994 12.6 4.39994 9.39999 7.60006 9.79999L7.60006 12.28L14.0001 7.63934Z"
                stroke={color}
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const IncreaseIcon: FC<IProps> = ({ color = "#31AC37", width = 15, height = 15 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.48325 4.23575L10.8405 5.59303L7.94815 8.48542L5.57735 6.11461L1.18543 10.5125L2.02114 11.3482L5.57735 7.79196L7.94815 10.1628L11.6822 6.43467L13.0395 7.79196V4.23575H9.48325Z"
                fill={color}
            />
        </svg>
    );
};

export const DecreaseIcon: FC<IProps> = ({ color = "#E62C33", width = 12, height = 11 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.29342 9.02422L9.64999 7.66765L6.75912 4.77678L4.38956 7.14634L-6.10352e-05 2.7508L0.835211 1.91553L4.38956 5.46988L6.75912 3.10031L10.4912 6.82645L11.8478 5.46988V9.02422H8.29342Z"
                fill={color}
            />
        </svg>
    );
};

export const PremiumIcon: FC<IProps> = ({ color = "#000000", width = 63, height = 63 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M45.6413 38.505H11.175C10.7624 38.5087 10.3594 38.381 10.0243 38.1403C9.68923 37.8996 9.43948 37.5584 9.31126 37.1663L0.937512 11.625C0.80922 11.2578 0.795757 10.8601 0.898914 10.485C1.00207 10.1099 1.21696 9.77506 1.51501 9.52503C1.80939 9.26675 2.17496 9.10348 2.56377 9.05663C2.95258 9.00979 3.34646 9.08156 3.69376 9.26253L18.9713 16.875L26.8463 1.59753C27.0278 1.29296 27.2853 1.04076 27.5936 0.865612C27.9019 0.690469 28.2504 0.598389 28.605 0.598389C28.9596 0.598389 29.3081 0.690469 29.6164 0.865612C29.9247 1.04076 30.1822 1.29296 30.3638 1.59753L38.2388 16.9013L53.5425 9.28878C53.8858 9.11002 54.2752 9.03942 54.6595 9.08628C55.0437 9.13313 55.4047 9.29525 55.695 9.55128C55.9463 9.82667 56.1143 10.1678 56.1794 10.5349C56.2444 10.902 56.2039 11.28 56.0625 11.625L47.505 37.14C47.3814 37.5371 47.1336 37.884 46.7981 38.1297C46.4626 38.3754 46.0571 38.507 45.6413 38.505V38.505ZM12.75 34.5675H44.25L50.8388 14.9063L38.055 21.2588C37.8261 21.3759 37.5762 21.4464 37.3198 21.4662C37.0635 21.486 36.8057 21.4547 36.5615 21.3741C36.3173 21.2935 36.0916 21.1653 35.8973 20.9968C35.7031 20.8283 35.5443 20.6229 35.43 20.3925L28.5 6.79503L21.5963 20.3925C21.482 20.6229 21.3232 20.8283 21.1289 20.9968C20.9347 21.1653 20.7089 21.2935 20.4648 21.3741C20.2206 21.4547 19.9628 21.486 19.7064 21.4662C19.4501 21.4464 19.2002 21.3759 18.9713 21.2588L6.13501 14.8275L12.75 34.5675Z"
                fill={color}
            />
            <path
                d="M28.5 26.64C27.98 26.6332 27.4831 26.4236 27.1154 26.0559C26.7476 25.6881 26.538 25.1913 26.5312 24.6713C26.5278 24.414 26.5754 24.1586 26.6715 23.9198C26.7676 23.6811 26.9101 23.4639 27.0908 23.2807C27.2716 23.0975 27.4869 22.9521 27.7243 22.8528C27.9617 22.7536 28.2164 22.7025 28.4737 22.7025V22.7025C28.9959 22.7025 29.4967 22.9099 29.8659 23.2791C30.2351 23.6484 30.4425 24.1491 30.4425 24.6713C30.4425 25.1934 30.2351 25.6942 29.8659 26.0634C29.4967 26.4326 28.9959 26.64 28.4737 26.64H28.5Z"
                fill={color}
            />
        </svg>
    );
};

export const PointTableLeaderIcon: FC<IProps> = ({ color = "#000000", width = 9, height = 9 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.3573 2.57203H8.7848V3.42703H8.3573V2.57203Z" fill={color} />
            <path d="M7.49951 2.14172H8.35732V2.57203H7.49951V2.14172Z" fill={color} />
            <path d="M7.49952 5.14265H7.92983V3.85734H8.35733V3.42703H7.49952V2.57203H7.07202V3.85734H7.49952V5.14265Z" fill={color} />
            <path
                d="M7.07205 5.57016H6.64174V6.00047H7.07205V6.42797H1.92799V6.00047H2.3583V5.57016H1.92799V5.14266H1.50049V7.28579H1.92799V7.71329H7.07205V7.28579H7.49955V5.14266H7.07205V5.57016Z"
                fill={color}
            />
            <path d="M6.64172 3.85735H7.07204V4.28485H6.64172V3.85735Z" fill={color} />
            <path d="M6.21423 4.28484H6.64173V4.71516H6.21423V4.28484Z" fill={color} />
            <path d="M5.78674 4.71516H6.21424V5.14266H5.78674V4.71516Z" fill={color} />
            <path d="M5.35645 5.57016H6.21426V6.00047H5.35645V5.57016Z" fill={color} />
            <path d="M5.35645 3.85735H5.78676V4.71516H5.35645V3.85735Z" fill={color} />
            <path d="M4.92896 2.99953H5.35645V3.85734H4.92896V2.99953Z" fill={color} />
            <path d="M4.92896 1.71422H5.35645V2.57203H4.92896V1.71422Z" fill={color} />
            <path d="M4.07104 2.57203H4.92886V2.99953H4.07104V2.57203Z" fill={color} />
            <path d="M4.07104 1.28672H4.92886V1.71422H4.07104V1.28672Z" fill={color} />
            <path d="M4.07104 5.14266H4.92886V6.00047H4.07104V5.14266Z" fill={color} />
            <path d="M3.64355 2.99953H4.07105V3.85734H3.64355V2.99953Z" fill={color} />
            <path d="M3.64355 1.71422H4.07105V2.57203H3.64355V1.71422Z" fill={color} />
            <path d="M3.21326 3.85735H3.64357V4.71516H3.21326V3.85735Z" fill={color} />
            <path d="M2.78577 5.57016H3.64358V6.00047H2.78577V5.57016Z" fill={color} />
            <path d="M2.78577 4.71516H3.21327V5.14266H2.78577V4.71516Z" fill={color} />
            <path d="M2.35815 4.28484H2.78565V4.71516H2.35815V4.28484Z" fill={color} />
            <path d="M1.92798 3.85735H2.35829V4.28485H1.92798V3.85735Z" fill={color} />
            <path d="M0.6427 3.42703V3.85734H1.07301V5.14265H1.50051V3.85734H1.92801V2.57203H1.50051V3.42703H0.6427Z" fill={color} />
            <path d="M0.6427 2.14172H1.50051V2.57203H0.6427V2.14172Z" fill={color} />
            <path d="M0.215088 2.57203H0.642588V3.42703H0.215088V2.57203Z" fill={color} />
        </svg>
    );
};

export const CheckIcon: FC<IProps> = ({ color = "#31AC37", width = 16, height = 14 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1.75L4.55903 12.25L1 8.67081" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
