import useThemeMode from "../../../hooks/useTheme";
import { ArrowIcon } from "../../../constants/icons";
import { Box } from "@mui/material";
import { Dispatch, FC, SetStateAction, useEffect } from "react";

declare global {
    interface Window {
        googletag: any;
    }
}

type IProps = {
    setIsLoadAdwert: Dispatch<SetStateAction<boolean>>;
};

// sx={{ minWidth: "320px", minHeight: "50px" }}
const Adwert: FC<IProps> = (props) => {
    const { theme }: any = useThemeMode();
    const { setIsLoadAdwert } = props;

    useEffect(() => {
        window?.googletag?.cmd?.push(function () {
            window.googletag
                ?.defineSlot("/21766134743/dailywords_puzzlepage_livebanner", ["fluid"], "div-gpt-ad-1726843534995-0")
                ?.addService(window.googletag.pubads());

            window.googletag.enableServices();

            window.googletag.pubads().addEventListener("slotRenderEnded", function (event: any) {
                if (!event.isEmpty) {
                    setIsLoadAdwert(true);
                    console.log("Reklam başarıyla yüklendi:", event.slot.getSlotElementId());
                } else {
                    setIsLoadAdwert(false);
                    console.log("Reklam boş geldi:", event.slot.getSlotElementId());
                }
            });
            window.googletag.display("div-gpt-ad-1726843534995-0");
        });
    }, []);

    return (
        <Box id="adwert" sx={{ background: theme.palette.adwert.background }}>
            <Box id="div-gpt-ad-1726843534995-0" />

            <Box>
                <ArrowIcon width={20} height={12} />
            </Box>
        </Box>
    );
};

export default Adwert;
