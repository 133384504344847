import { Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FC, ReactNode, useRef } from "react";
import Slider, { Settings } from "react-slick";
import useThemeMode from "../../hooks/useTheme";
import { NextIcon, PrevIcon } from "../../constants/icons";

type IProps = {
    children: ReactNode;
    configs?: Settings;
};

const BundleSlider: FC<IProps> = (props) => {
    const { children, configs } = props;
    const sliderRef = useRef<any>(null);
    const { theme, blindMode }: any = useThemeMode();

    const defaultSliderConfigs: Settings = {
        arrows: false,
        infinite: false,
        dots: true,
        accessibility: false,
        appendDots: (dots: ReactNode) => (
            <Box
                sx={{
                    "& .slick-active button::before": { color: `${blindMode ? "#599FAC" : "#31AC37"} !important`, fontSize: 18 },
                    "& :not(.slick-active) button::before": {
                        color: (theme: any) => `${theme.palette.settingDialog.sliderDot} !important`,
                        fontSize: "10px !important",
                        opacity: 1,
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    bottom: -40,
                }}>
                <Box component="span" onClick={prevHandler} sx={{ cursor: "pointer", mr: 3 }}>
                    <PrevIcon color={theme.palette.settingDialog.prevNext} />
                </Box>
                {dots}
                <Box component="span" onClick={nextHandler} sx={{ cursor: "pointer", ml: 3 }}>
                    <NextIcon color={theme.palette.settingDialog.prevNext} />
                </Box>
            </Box>
        ),
    };

    const customConfig: Settings = { ...defaultSliderConfigs, ...configs };

    const prevHandler = (): void => {
        sliderRef.current.slickPrev();
    };

    const nextHandler = (): void => {
        sliderRef.current.slickNext();
    };

    return (
        <Slider ref={sliderRef} {...customConfig}>
            {children}
        </Slider>
    );
};

export default BundleSlider;
