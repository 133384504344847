import { useState } from "react";
import { StatisticContext } from "../contexts/StatisticContext";
import { StatisticTabs } from "../../utils/enums";

const StatisticProvider = (props: any) => {
    const [isOpenStatisticDialog, setIsOpenStatisticDialog] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<StatisticTabs>(StatisticTabs.GameSummary);

    return (
        <StatisticContext.Provider value={{ isOpenStatisticDialog, setIsOpenStatisticDialog, selectedTab, setSelectedTab }}>
            {props.children}
        </StatisticContext.Provider>
    );
};

export default StatisticProvider;
