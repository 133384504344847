import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { Fragment, useMemo } from "react";
import { WordleStateLocale, wordleStore } from "../../features/wordle/WordleStore";
import { useLocales } from "../../hooks";
import useThemeMode from "../../hooks/useTheme";

const WordleTimerContent = () => {
    const { theme }: any = useThemeMode();
    const { currentLang } = useLocales();
    const wordleState: WordleStateLocale = wordleStore();

    return useMemo(
        () => (
            <Box id="wordle-timer-time">
                {new Date(wordleState[currentLang.value].gameTime).getHours() > 0 && (
                    <Fragment>
                        <Typography className="wordle-timer-time-part" sx={{ color: theme.palette.wordleTimer.time }}>
                            {format(wordleState[currentLang.value].gameTime, "HH")}
                        </Typography>
                        <Typography className="timer-divider" sx={{ color: theme.palette.wordleTimer.time }}>
                            :
                        </Typography>
                    </Fragment>
                )}
                <Typography className="wordle-timer-time-part" sx={{ color: theme.palette.wordleTimer.time }}>
                    {format(wordleState[currentLang.value].gameTime, "mm")}
                </Typography>
                <Typography className="timer-divider" sx={{ color: theme.palette.wordleTimer.time }}>
                    :
                </Typography>
                <Typography className="wordle-timer-time-part" sx={{ color: theme.palette.wordleTimer.time }}>
                    {format(wordleState[currentLang.value].gameTime, "ss")}
                </Typography>
            </Box>
        ),
        [wordleState, currentLang, theme]
    );
};

export default WordleTimerContent;
