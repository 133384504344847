import { SxProps } from "@mui/material";
import { createContext } from "react";

export type NotificationAlertContextType = {
    showAlert: (message: string, timer?: number, icon?: any, callBackFnc?: () => void, style?: SxProps) => void;
};

export const NotificationAlertContext = createContext<NotificationAlertContextType>({
    showAlert: () => {},
});
