import { FC, ReactNode, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { NotificationAlertContext, NotificationAlertContextType } from "../contexts/NotificationAlertContext";
import { Box, Dialog, DialogContent, DialogContentText, Slide, SxProps } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

type Alert = {
    message: string;
    icon?: any;
    timer?: number;
    style?: SxProps;
    callBackFnc?: () => void;
};

type NotificationAlertContextProvider = {
    children: ReactNode;
};

const NotificationAlertProvider: FC<NotificationAlertContextProvider> = ({ children }) => {
    const [alert, setAlert] = useState<Alert | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const hideAlert = useCallback(() => {
        alert?.callBackFnc && alert.callBackFnc();

        setAlert(null);
        setIsOpen(false);
    }, [alert]);

    useEffect(() => {
        if (!alert || !isOpen) return;

        const timeOut: NodeJS.Timeout = setTimeout(hideAlert, alert?.timer ?? 3000);

        return () => clearTimeout(timeOut);
    }, [alert, isOpen, hideAlert]);

    const showAlert = useCallback((message: string, timer?: number, icon?: any, callBackFnc?: any, style?: SxProps) => {
        setAlert({ message, icon, timer, callBackFnc, style } as Alert);
        setIsOpen(true);
    }, []);

    const contextValue: NotificationAlertContextType = useMemo(() => ({ showAlert }), [showAlert]);

    return (
        <NotificationAlertContext.Provider value={contextValue}>
            {alert && (
                <Dialog
                    id="notification-dialog"
                    open={isOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={hideAlert}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{ ...(alert.style && alert.style) }}>
                    <DialogContent className="content">
                        <DialogContentText className="content-text">{alert.message}</DialogContentText>
                        {alert.icon && <Box className="content-icon">{alert.icon}</Box>}
                    </DialogContent>
                </Dialog>
            )}

            {children}
        </NotificationAlertContext.Provider>
    );
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default NotificationAlertProvider;
