import { Box, Button, Grid, Hidden, Typography } from "@mui/material";
import { SettingContext } from "../../states";
import "../../scss/screens/wordle-screen.scss";
import { useGuess, useLocales, usePrevious, useTheme } from "../../hooks";
import { deleteCurrentScore, floatToFixedFloat, isExist } from "../../utils/methods";
import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import { SettingContextType } from "../../states/contexts/SettingContext";
import { Adwert, Keyboard, SettingDialog, Sponsor, Wordle, WordleTimer } from "./common";
import { WordleStateLocale, wordleStore } from "../../features/wordle/WordleStore";
import { AuthState, authStore } from "../../features/authenticate/AuthStore";
import WordleScreenInitialHandler from "./common/WordleScreenInitialHandler";
import InfoDialog from "./common/dialogs/InfoDialog";
import useAlert from "../../hooks/useAlert";

type IProps = {
    isCompleteSplashScreen: boolean;
};

const WordleScreen: FC<IProps> = (props) => {
    const { isCompleteSplashScreen } = props;
    const { theme } = useTheme();
    const { currentLang } = useLocales();
    const previousLang: string = usePrevious<string>(currentLang.value);
    const settingContext: SettingContextType = useContext(SettingContext);
    const { guess, setGuess, addGuessLetter }: any = useGuess();
    const wordleScreenBoxRef: any = useRef<any>(null);
    const [boxHeight, setBoxHeight] = useState<number>(0);
    const [boxWidth, setBoxWidth] = useState<number>(0);
    const [useEffectTrigger, setUseEffectTrigger] = useState<boolean>(false);
    const adwertRef: any = useRef<any>(null);
    const wordleTimerRef: any = useRef<any>(null);
    const sponsorRef: any = useRef<any>(null);
    const keyboardRef: any = useRef<any>(null);
    const [totalHeight, setTotalHeight] = useState<number>(0);
    const wordleState: WordleStateLocale = wordleStore();
    const authState: AuthState = authStore();
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState<boolean>(false);
    const [maxSize, setMaxSize] = useState<number>(0);
    const [isLoadAdwert, setIsLoadAdwert] = useState<boolean>(false);
    const { showAlert } = useAlert();

    useEffect(() => {
        const interval = setInterval(() => {
            setUseEffectTrigger((curr: boolean) => !curr);
        }, 100);

        const timeout = setTimeout(() => {
            clearInterval(interval);
        }, 300);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (wordleScreenBoxRef?.current) {
                const tmpRect: DOMRect = wordleScreenBoxRef?.current.getBoundingClientRect();

                setBoxHeight(tmpRect.height);
                setBoxWidth(tmpRect.width);
            }
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, [wordleScreenBoxRef.current, useEffectTrigger]);

    useEffect(() => {
        const handleResize = () => {
            let totalHeight: number = 0;

            if (adwertRef?.current) {
                const tmpRect: DOMRect = adwertRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (wordleTimerRef?.current) {
                const tmpRect: DOMRect = wordleTimerRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (sponsorRef?.current) {
                const tmpRect: DOMRect = sponsorRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            if (keyboardRef?.current) {
                const tmpRect: DOMRect = keyboardRef?.current.getBoundingClientRect();

                totalHeight += tmpRect.height;
            }

            setTotalHeight(totalHeight);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, [adwertRef.current, wordleTimerRef.current, sponsorRef.current, keyboardRef.current, authState.isPremium, useEffectTrigger]);

    useEffect(() => {
        const calcMaxSize: number = floatToFixedFloat(boxHeight - totalHeight - parseFloat(theme.spacing(2)));

        setMaxSize(calcMaxSize);
    }, [boxHeight, totalHeight, theme]);

    useEffect(() => {
        if (currentLang.value !== previousLang && isExist(previousLang)) setGuess("");
    }, [currentLang, previousLang, setGuess]);

    return (
        <Grid container spacing={{ xs: 1, sm: 2 }} id="wordle-screen" ref={wordleScreenBoxRef}>
            <WordleScreenInitialHandler />

            {!authState.isPremium && (
                <Grid item xs={12} ref={adwertRef} sx={{ display: isLoadAdwert ? "flex !important" : "none !important" }}>
                    <Adwert setIsLoadAdwert={setIsLoadAdwert} />
                </Grid>
            )}

            <Hidden smDown>
                <Grid item xs={12} ref={wordleTimerRef}>
                    <WordleTimer isCompleteSplashScreen={isCompleteSplashScreen} />
                </Grid>
            </Hidden>

            <Box id="wordle-screen-content">
                <Grid item xs={12} maxHeight={`${maxSize}px`}>
                    <Wordle guess={guess} setGuess={setGuess} sizeHeight={maxSize} sizeWidth={boxWidth} />
                </Grid>

                {!authState.isPremium && (
                    <Hidden mdDown>
                        <Grid item xs={12} ref={sponsorRef}>
                            <Sponsor />
                        </Grid>
                    </Hidden>
                )}

                <Grid item xs={12} ref={keyboardRef}>
                    <Keyboard addGuessLetter={addGuessLetter} />
                </Grid>

                {process.env.NODE_ENV === "development" && (
                    <Fragment>
                        <Box sx={{ position: "absolute", top: "40%", right: 0, transform: "translateY(-40%)" }}>
                            <Button
                                variant="contained"
                                onClick={(event: any) => {
                                    wordleState.clear();
                                    event.currentTarget.blur();
                                }}>
                                <Typography>Temizle FE</Typography>
                            </Button>
                        </Box>
                        <Box sx={{ position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)" }}>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    deleteCurrentScore()
                                        .then(() => showAlert("Backend skor temizlendi"))
                                        .catch(() => showAlert("İşlem başarısız"))
                                }>
                                <Typography>Sil BE</Typography>
                            </Button>
                        </Box>
                    </Fragment>
                )}
                <Box sx={{ position: "absolute", top: "45%", right: 0, transform: "translateY(-45%)" }}>
                    <Button variant="contained" onClick={() => setIsInfoDialogOpen(true)}>
                        <Typography>Info</Typography>
                    </Button>
                </Box>
            </Box>

            {settingContext.isOpenSettingDialog && (
                <SettingDialog isOpen={settingContext.isOpenSettingDialog} setIsOpen={settingContext.setIsOpenSettingDialog} />
            )}
            {isInfoDialogOpen && <InfoDialog isOpen={isInfoDialogOpen} setIsOpen={setIsInfoDialogOpen} />}
        </Grid>
    );
};

export default WordleScreen;
