import { useState } from "react";
import { SettingContext } from "..";

const SettingProvider = (props: any) => {
    const [isOpenSettingDialog, setIsOpenSettingDialog] = useState<boolean>(false);
    const [isLoadingComputeGuess, setIsLoadingComputeGuess] = useState<boolean>(false);

    return (
        <SettingContext.Provider
            value={{
                isOpenSettingDialog,
                setIsOpenSettingDialog,
                isLoadingComputeGuess,
                setIsLoadingComputeGuess,
            }}>
            {props.children}
        </SettingContext.Provider>
    );
};

export default SettingProvider;
