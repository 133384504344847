import { createContext, Dispatch, SetStateAction } from "react";

export type SettingContextType = {
    isOpenSettingDialog: boolean;
    setIsOpenSettingDialog: Dispatch<SetStateAction<boolean>>;
    isLoadingComputeGuess: boolean;
    setIsLoadingComputeGuess: Dispatch<SetStateAction<boolean>>;
};

export const SettingContext = createContext<SettingContextType>({ isOpenSettingDialog: false } as SettingContextType);
