import { createContext, Dispatch, SetStateAction } from "react";
import { StatisticTabs } from "../../utils/enums";

export type StatisticContextType = {
    isOpenStatisticDialog: boolean;
    setIsOpenStatisticDialog: Dispatch<SetStateAction<boolean>>;
    selectedTab: StatisticTabs;
    setSelectedTab: Dispatch<SetStateAction<StatisticTabs>>;
};

export const StatisticContext = createContext<StatisticContextType>({ isOpenStatisticDialog: false } as StatisticContextType);
